/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";
// @import "../node_modules/@fullcalendar/daygrid/main.min.css";
// @import "../node_modules/@fullcalendar/timegrid/main.min.css";
@import "assets/demo/flags/flags.css";

// JSON Schema Form Generator
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";


.mxRubberband {
	// z-index: 1000000;
	position: absolute;
	overflow: hidden;
	border-style: solid;
	border-width: 1px;
	border-color: #0494c5;
	background: #0077FF;
}

.report-container {
    height: 77.5vh;
    // margin: 8px auto;
    width: 100%;
	border: none;
}
iframe {
	border: none !important;
}
.layout-menu-dark .menu-wrapper {
	background-color: #53389E !important;
}
.dot {
    width: 24px;
    height: 24px;
    background: #FCFAFF;
    border-radius: 100%;
    display: inline-block;
    animation: slide 1s infinite;
}

.dot:nth-child(1) {
    animation-delay: (.1s);
    background: #E9D7FE;
}

.dot:nth-child(2) {
    animation-delay: (.2s);
    background: #D6BBFB;
}

.dot:nth-child(3) {
    animation-delay: (.3s);
    background: #B692F6;
}

.dot:nth-child(4) {
    animation-delay: (.4s);
    background: #9E77ED;
}

.dot:nth-child(5) {
    animation-delay: (.5s);
    background: #FDB022;
}
